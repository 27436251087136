import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";
import { PiEraserFill } from "react-icons/pi";

import Loader from "@/ui-lib/loader/Loader";
import usePageAdminOrganizationEdit from "./usePageAdminOrganizationEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormButton from "@/ui-lib/form-button/FormButton";
import copyToClipboard from "@/utils/copyToClipBoard";
import { OrganizationStatus } from "@/domains/user/types/Organization";
import FormCancelButton from "@/ui-lib/form-cancel-button/FormCancelButton";

import "./pageAdminOrganizationEdit.scss";

export interface PageAdminOrganizationEditFormInputs {
  name?: string;
  numberOfTokens?: number;
  isActive?: boolean;
  expirationDate?: string;
  status?: string;
  apiKey?: string;
}

const PageAdminOrganizationEdit = () => {
  const { t } = useTranslation();

  const { organization, updateOrganization, isUpdateOrganizationLoading, renewApiKey, isLoading } =
    usePageAdminOrganizationEdit();

  // Reset the form values when the organization is fetched
  useEffect(() => {
    reset({
      name: organization?.name,
      numberOfTokens: organization?.numberOfTokens,
      isActive: organization?.isActive,
      expirationDate: organization?.expirationDate,
      status: t(`admin.user-management.organization.${organization?.status}`),
      apiKey: organization?.apiKey,
    });
  }, [organization]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm<PageAdminOrganizationEditFormInputs>({
    defaultValues: {
      name: organization?.name,
      numberOfTokens: organization?.numberOfTokens,
      isActive: organization?.isActive,
      expirationDate: organization?.expirationDate,
      status: t(`admin.user-management.organization.${organization?.status}`),
      apiKey: organization?.apiKey,
    },
  });

  // Block the user from leaving the page if there is unsaved data
  useBlocker(() => isDirty && !confirm(t("common.unsaved-data-confirm-leave-page")));

  if (isLoading) {
    return <Loader rootClassName="page-admin-organization-edit-loader" />;
  }

  return (
    <div className="page-admin-organization-edit">
      <form onSubmit={handleSubmit(updateOrganization)}>
        <div className="form-fields">
          <FormInput
            id="name"
            className="form-input"
            labelName={t("admin.user-management.organization.name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user-management.organization.name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
          />
          <FormInput
            id="numberOfTokens"
            className="form-input"
            labelName={t("admin.user-management.organization.number-of-tokens")}
            type={InputType.text}
            disabled={true}
            register={register}
          />
          <div className="api-key-row">
            <FormInput
              id="apiKey"
              className="form-input api-key-input"
              labelName={t("admin.user-management.organization.api-key")}
              type={InputType.text}
              disabled={true}
              register={register}
            />
            <FormButton
              labelName="Copier"
              className="api-key-button"
              onClick={() => {
                if (!organization?.apiKey) return;
                copyToClipboard(organization.apiKey);
              }}
            />
            <FormButton labelName="Renouveller" className="api-key-button" onClick={renewApiKey} />
          </div>
          <div className="status-row">
            <FormInput
              id="status"
              className="form-input status-input"
              labelName={t("admin.user-management.organization.status")}
              type={InputType.text}
              disabled={true}
              register={register}
            />
            <FormButton
              labelName={organization?.isActive ? t("common.disable") : t("common.enable")}
              className="status-button"
              disabled={organization?.status === OrganizationStatus.EXPIRED}
              onClick={() => {
                updateOrganization({
                  isActive: !organization?.isActive,
                });
              }}
            />
            <FormInput
              id="expirationDate"
              labelName={t("admin.user-management.organization.expiration-date")}
              type={InputType.dateTimeLocal}
              register={register}
            />
            <button
              className="expiration-date-erasor"
              type="button"
              onClick={() => setValue("expirationDate", "", { shouldDirty: true })}
            >
              <PiEraserFill size={25} />
            </button>
          </div>
        </div>
        <div className="form-buttons">
          <FormCancelButton
            onClick={() => reset()}
            isDisabled={!isDirty || isUpdateOrganizationLoading}
          />
          <FormSubmit value={t("admin.save")} disabled={!isDirty || isUpdateOrganizationLoading} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminOrganizationEdit;
