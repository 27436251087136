import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "react-toastify";

import useCompetitions from "@rugby-former/hooks/useCompetitions";
import useOrganization from "@user/hooks/useOrganization";
import { Competition } from "@rugby-former/types/Competitions";
import { APIError } from "@user/types/ApiError";
import OrganizationCompetitionsApi, {
  UpdateOrganizationCompetitionsInputs,
  CreateOrganizationCompetitionsInputs,
} from "@user/api/OrganizationCompetitionsApi";
import { OrganizationRugbyCompetition } from "@user/types/OrganizationRugbyCompetition";
import {
  OrganizationSetting,
  OrganizationSettingEntityType,
} from "@user/types/OrganizationSetting";
import { CreateOrganizationSettingInputs } from "@user/api/OrganizationsApi";
import { Sport, SportKey } from "@user/types/Sport";
import useOrganizationSetting from "@user/hooks/useOrganizationSetting";
import { cacheKeyInvalidator } from "@user/api/apiCache";
import useSports from "@user/hooks/useSports";

export interface ExtendedCompetition extends Competition {
  sportName: string;
  isFavorite: boolean;
  organizationSettingId?: string;
  organizationCompetitionId?: string;
  hasDynamicReportAccess: boolean;
  hasFreeAccessToGames: boolean;
  hasGamesPurchaseBlocked: boolean;
  hasCompetitionDownloadsBlocked: boolean;
  hasGamesDownloadsBlocked: boolean;
  hasFreeAccessToTeams: boolean;
}

function usePageAdminOrganizationCompetitions(): {
  extendedCompetitions: ExtendedCompetition[];
  sportRugby?: Sport;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  createOrganizationCompetition: (inputs: CreateOrganizationCompetitionsInputs) => void;
  updateOrganizationCompetition: (
    organizationCompetitionId: string,
    inputs: UpdateOrganizationCompetitionsInputs,
  ) => void;
  createOrganizationSetting: (
    organizationId: string,
    settingInputs: CreateOrganizationSettingInputs,
  ) => void;
  deleteOrganizationSetting: (organizationId: string, organizationSettingId: string) => void;
} {
  const { id: organizationId } = useParams();
  const queryClient = useQueryClient();

  const {
    organizationCompetitions,
    organizationSettings,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useOrganization({
    organizationId,
    fetchCompetitions: true,
    fetchSettings: true,
  });

  const {
    competitions,
    isLoading: isAllCompetitionsLoading,
    isError: isAllCompetitionsError,
  } = useCompetitions({
    skip: !organizationId,
  });

  const { sports, isLoading: isSportsLoading, isError: isSportsError } = useSports();

  const { createOrganizationSetting, deleteOrganizationSetting } = useOrganizationSetting({
    skip: true,
  });

  // Build sport Rugby XV
  const sportRugby = sports.find((sport) => sport.key === SportKey.RUGBY_XV);

  // Build the extended competitions which will be used in the page table
  const extendedCompetitions: ExtendedCompetition[] =
    competitions?.map((competition) => {
      const organizationCompetition = organizationCompetitions?.find((organizationCompetition) => {
        return organizationCompetition.competitionId === competition.id;
      });

      const organizationSettingWithFavoriteCompetition = organizationSettings.find(
        (setting: OrganizationSetting) =>
          setting.entityType === OrganizationSettingEntityType.FAVORITE_COMPETITION &&
          setting.entityId === competition.id.toString() &&
          setting.settingValue === "true",
      );

      const extendedCompetition: ExtendedCompetition = {
        ...competition,
        isFavorite: !!organizationSettingWithFavoriteCompetition,
        organizationSettingId: organizationSettingWithFavoriteCompetition?.id,
        sportName: sportRugby?.name ?? "",
        organizationCompetitionId: organizationCompetition?.id,
        hasDynamicReportAccess: organizationCompetition?.hasDynamicReportAccess ?? false,
        hasFreeAccessToGames: organizationCompetition?.hasFreeAccessToGames ?? false,
        hasGamesPurchaseBlocked: organizationCompetition?.hasGamesPurchaseBlocked ?? false,
        hasCompetitionDownloadsBlocked:
          organizationCompetition?.hasCompetitionDownloadsBlocked ?? false,
        hasGamesDownloadsBlocked: organizationCompetition?.hasGamesDownloadsBlocked ?? false,
        hasFreeAccessToTeams: organizationCompetition?.hasFreeAccessToTeams ?? false,
      };

      return extendedCompetition;
    }) ?? [];

  // Create a new organization competition
  const { mutate: createCompetitionsRugby, isPending: isCreationPending } = useMutation({
    mutationFn: (inputs: CreateOrganizationCompetitionsInputs) => {
      return OrganizationCompetitionsApi.create(inputs);
    },
    onSuccess: (data: OrganizationRugbyCompetition) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationRugbyCompetitions(data.organizationId),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t("admin.user-management.organization-competitions.organization-competition-update-failed"),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function createOrganizationCompetition(inputs: CreateOrganizationCompetitionsInputs) {
    createCompetitionsRugby(inputs);
  }

  // Update an organization competition
  const { mutate: updateCompetitionsRugby } = useMutation({
    mutationFn: (variables: {
      organizationCompetitionId: string;
      inputs: UpdateOrganizationCompetitionsInputs;
    }) =>
      OrganizationCompetitionsApi.update(
        variables.organizationCompetitionId ? variables.organizationCompetitionId : "",
        variables.inputs,
      ),
    onSuccess: (data: OrganizationRugbyCompetition) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationRugbyCompetitions(data.organizationId),
      });
    },

    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t("admin.user-management.organization-competitions.organization-competition-update-failed"),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function updateOrganizationCompetition(
    organizationCompetitionId: string,
    inputs: UpdateOrganizationCompetitionsInputs,
  ) {
    updateCompetitionsRugby({ organizationCompetitionId, inputs });
  }

  const isLoading = isOrganizationLoading || isAllCompetitionsLoading || isSportsLoading;

  const isError = isOrganizationError || isAllCompetitionsError || isSportsError;

  const isFetching = isCreationPending;

  return {
    extendedCompetitions,
    sportRugby,
    isLoading,
    isError,
    isFetching,
    createOrganizationCompetition,
    updateOrganizationCompetition,
    createOrganizationSetting,
    deleteOrganizationSetting,
  };
}

export default usePageAdminOrganizationCompetitions;
