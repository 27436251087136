import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import OrganizationsApi, { UpdateOrganizationInputs } from "@user/api/OrganizationsApi";
import useOrganization from "@user/hooks/useOrganization";
import { APIError } from "@user/types/ApiError";
import { Organization } from "@user/types/Organization";
import { cacheKeyInvalidator } from "@user/api/apiCache";

function usePageAdminOrganizationEdit(): {
  organization: Organization | undefined;
  updateOrganization: (inputs: UpdateOrganizationInputs) => void;
  isUpdateOrganizationLoading: boolean;
  renewApiKey: () => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Fetch the organization
  const {
    organization,
    isLoading: isOrganizationLoading,
    updateOrganization,
    isUpdateOrganizationLoading,
  } = useOrganization({
    organizationId,
    fetchDetails: !organizationId,
  });

  // Renew API key
  const { mutate: renewApiKeyInApi } = useMutation({
    mutationFn: organizationId ? () => OrganizationsApi.renewApiKey(organizationId) : undefined,
    onSuccess: (data: Organization) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organization(data.id),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizations(),
      });
      toast.success(t("admin.user-management.organization.api-key-renewal-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user-management.organization.api-key-renewal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function renewApiKey() {
    renewApiKeyInApi();
  }

  // Build global isLoading flag
  const isLoading = isOrganizationLoading;

  return {
    organization,
    updateOrganization,
    isUpdateOrganizationLoading,
    renewApiKey,
    isLoading,
  };
}

export default usePageAdminOrganizationEdit;
