import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { ApiRoute, HttpMethod } from "@user/types/ApiRoute";
import { PaginatedModel } from "@user/types/PaginatedModel";
import {
  castApiApiRouteToApiRoute,
  castApiPaginationInfoToPaginationInfo,
  getDefaultHeaders,
} from "./utils";
import { APIApiRoute, APIPaginatedModel } from "./apiTypes";
import { buildUrlWithParams } from "@/utils/URL";

export interface CreateApiRouteInputs {
  domainId: string; // UUID v4
  method: HttpMethod;
  path: string;
  description?: string;
}

export interface UpdateApiRouteInputs {
  domainId?: string; // UUID v4
  method?: HttpMethod;
  path?: string;
  description?: string;
}

export interface IndexApiRouteOptions {
  domainId?: string; // UUID v4
  method?: HttpMethod;
  path?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export default class ApiRoutesApi {
  static domain: string = "user";
  static resource: string = "routes";

  static async create(createApiRouteInputs: CreateApiRouteInputs): Promise<ApiRoute> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}`;

    const data = {
      domain_id: createApiRouteInputs.domainId,
      method: createApiRouteInputs.method,
      path: createApiRouteInputs.path,
      description: createApiRouteInputs.description,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: data,
    });

    const apiApiRoute: APIApiRoute = response.data;

    const apiRoute = castApiApiRouteToApiRoute(apiApiRoute);

    return apiRoute;
  }

  static async show(id: string): Promise<ApiRoute> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiApiRoute: APIApiRoute = response.data;

    const apiRoute = castApiApiRouteToApiRoute(apiApiRoute);

    return apiRoute;
  }

  static async index(
    options: Partial<IndexApiRouteOptions> = {},
  ): Promise<PaginatedModel<ApiRoute>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
      sort_by: options.sortBy ? options.sortBy : "path:asc",
      domain_id: options.domainId,
      method: options.method,
      path: options.path,
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedApiRoutes: APIPaginatedModel<APIApiRoute> = response.data;

    const paginatedApiRoutes: PaginatedModel<ApiRoute> = {
      items: apiPaginatedApiRoutes.items.map((apiApiRoute) =>
        castApiApiRouteToApiRoute(apiApiRoute),
      ),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedApiRoutes.pagination),
    };

    return paginatedApiRoutes;
  }

  static async getAll(): Promise<ApiRoute[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/all`;

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiApiRoutes: APIApiRoute[] = response.data;

    const apiRoutes = apiApiRoutes.map((apiApiRoute) => castApiApiRouteToApiRoute(apiApiRoute));

    return apiRoutes;
  }

  static async update(id: string, updateApiRouteInputs: UpdateApiRouteInputs): Promise<ApiRoute> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const data = {
      domain_id: updateApiRouteInputs.domainId,
      method: updateApiRouteInputs.method,
      path: updateApiRouteInputs.path,
      description: updateApiRouteInputs.description,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: data,
    });

    const apiApiRoute: APIApiRoute = response.data;

    const apiRoute = castApiApiRouteToApiRoute(apiApiRoute);

    return apiRoute;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
