import { RouteObject } from "react-router-dom";

import PageAdminCompetitionList from "@rugby/pages/admin/competition/PageAdminCompetitionList";
import PageAdminCompetitionCreate from "@rugby/pages/admin/competition/PageAdminCompetitionCreate";
import PageAdminCompetitionEdit from "@rugby/pages/admin/competition/PageAdminCompetitionEdit";
import EnsureHasPermission from "@/auth/EnsureHasPermission";
import { PermissionKey } from "@user/types/Permission";

const routesAdminPeloteCestaPunta: RouteObject[] = [
  {
    path: "competitions",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.RUGBY_ADMINISTRATION]}
      >
        <PageAdminCompetitionList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "competitions/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.RUGBY_ADMINISTRATION]}
      >
        <PageAdminCompetitionCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "competitions/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.RUGBY_ADMINISTRATION]}
      >
        <PageAdminCompetitionEdit />
      </EnsureHasPermission>
    ),
  },
];

export default routesAdminPeloteCestaPunta;
