import { CreateCompetitionInputs } from "@rugby/api/CompetitionsApi";
import { Competition } from "@rugby/types/Competition";
import useCompetition from "@rugby/hooks/useCompetition";

function usePageAdminCompetitionCreate(): {
  createCompetition: (createCompetitionInputs: CreateCompetitionInputs) => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdCompetition: Competition | undefined;
} {
  const { createCompetition, isCreationPending, isCreationSuccess, createdCompetition } =
    useCompetition();

  return {
    createCompetition,
    isCreationPending,
    isCreationSuccess,
    createdCompetition,
  };
}

export default usePageAdminCompetitionCreate;
