import logo_aia_sports from "@assets/logos/logo_aia_sports_couleur_negatif.png";
import VerticalMenu, { MenuItem } from "@/ui-lib/vertical-menu/VerticalMenu";

import styles from "./AdminNavBar.module.scss";

interface IAdminNavBarProps {
  menuItems: MenuItem[];
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
}

function AdminNavBar({ menuItems, rootClassName, rootStyle }: IAdminNavBarProps) {
  return (
    <nav style={rootStyle} className={`${styles.adminNavBar} ${rootClassName}`}>
      <div className={styles.logo}>
        <img src={logo_aia_sports} alt="AIA Sports Logo" />
      </div>
      <VerticalMenu items={menuItems} rootClassName={styles.menu} />
      <div className={styles.appVersion}>v2025.01 - 2.0</div>
    </nav>
  );
}

export default AdminNavBar;
