import { useCallback } from "react";

import { Sport, SportKey } from "@rugby/types/Sport";
import useSportsUser from "@user/hooks/useSports";

interface useSportsOptions {}

function useSports(options: Partial<useSportsOptions> = {}): {
  getSportByKey: (key: SportKey) => Sport;
  isLoading: boolean;
  isError: boolean;
} {
  const {} = options;

  const { sports, isLoading, isError } = useSportsUser();

  // Get the sport by key - Works only for Rugby sports
  const getSportByKey = useCallback(
    (key: SportKey) => sports.find((sport) => sport.key === key) as Sport,
    [sports],
  );

  return {
    getSportByKey,
    isLoading,
    isError,
  };
}

export default useSports;
