export interface Organization {
  id: string; // UUID v4
  name: string;
  numberOfTokens: number;
  isActive: boolean;
  expirationDate?: string;
  status: OrganizationStatus; // Built from isActive and expirationDate
  apiKey?: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: string; // UUID v4
  updatedBy?: string; // UUID v4
}

export enum OrganizationStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  EXPIRED = "expired",
}
