import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { castApiCompetitionToCompetition, getDefaultHeaders } from "@rugby/api/utils";
import { Competition } from "@rugby/types/Competition";
import { APICompetition } from "@rugby/api/apiTypes";
import { buildUrlWithParams } from "@/utils/URL";
import { SportKey } from "@rugby/types/Sport";
import { Gender } from "@rugby/types/Gender";

export interface CreateCompetitionInputs {
  sportKey: SportKey;
  name: string;
  shortName: string;
  logoUrl: string;
  gender: Gender;
}

export interface UpdateCompetitionInputs {
  sportKey?: SportKey;
  name?: string;
  shortName?: string;
  logoUrl?: string;
  gender?: Gender;
}

export interface ListCompetitionsOptions {}

export default class CompetitionsApi {
  static domain: string = "rugby";
  static resource: string = "competitions";

  static async create(createCompetitionInputs: CreateCompetitionInputs): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}`;

    const body = {
      sport_key: createCompetitionInputs.sportKey,
      name: createCompetitionInputs.name,
      short_name: createCompetitionInputs.shortName,
      logo_url: createCompetitionInputs.logoUrl,
      gender: createCompetitionInputs.gender,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiCompetition: APICompetition = response.data;

    const role = castApiCompetitionToCompetition(apiCompetition);

    return role;
  }

  static async show(id: string): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiCompetition: APICompetition = response.data;

    const role = castApiCompetitionToCompetition(apiCompetition);

    return role;
  }

  static async list(options: Partial<ListCompetitionsOptions> = {}): Promise<Competition[]> {
    const {} = options;

    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {};
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiCompetitions: APICompetition[] = response.data;

    const competitions: Competition[] = apiCompetitions.map((apiCompetition) =>
      castApiCompetitionToCompetition(apiCompetition),
    );

    return competitions;
  }

  static async update(
    id: string,
    updateCompetitionInputs: UpdateCompetitionInputs,
  ): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const body = {
      sport_key: updateCompetitionInputs.sportKey,
      name: updateCompetitionInputs.name,
      short_name: updateCompetitionInputs.shortName,
      logo_url: updateCompetitionInputs.logoUrl,
      gender: updateCompetitionInputs.gender,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiCompetition: APICompetition = response.data;

    const role = castApiCompetitionToCompetition(apiCompetition);

    return role;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
