import { useMemo } from "react";

import { Competition } from "@rugby/types/Competition";
import useCompetitions from "@rugby/hooks/useCompetitions";
import useCompetition from "@rugby/hooks/useCompetition";
import { Sport } from "@rugby/types/Sport";
import useSports from "@rugby/hooks/useSports";

export interface ExtendedCompetition extends Competition {
  sport: Sport;
}

function usePageAdminCompetitionList(): {
  extendedCompetitions: ExtendedCompetition[];
  isLoading: boolean;
  deleteCompetition: (id: string) => void;
  isDeleteCompetitionLoading: boolean;
} {
  // Get the list of organizations
  const { competitions, isLoading: isLoadingCompetitions } = useCompetitions();

  // Get the sport utils functions
  const { getSportByKey, isLoading: isLoadingSports } = useSports();

  // Build the extended competitions
  const extendedCompetitions = useMemo(
    () =>
      competitions.map((competition) => ({
        ...competition,
        sport: getSportByKey(competition.sportKey),
      })),
    [competitions, getSportByKey],
  );

  // Get the competition utils functions
  const { deleteCompetition, isDeleteCompetitionLoading } = useCompetition();

  // Build the global isLoading flag
  const isLoading = isLoadingCompetitions || isLoadingSports;

  return {
    extendedCompetitions,
    isLoading,
    deleteCompetition,
    isDeleteCompetitionLoading,
  };
}

export default usePageAdminCompetitionList;
