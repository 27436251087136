import { useTranslation } from "react-i18next";

import styles from "./FormCancelButton.module.scss";

type FormCancelButtonProps = {
  onClick?: () => void;
  isDisabled?: boolean;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
};

function FormCancelButton({
  onClick,
  isDisabled = false,
  rootClassName,
  rootStyle,
}: FormCancelButtonProps) {
  const { t } = useTranslation();

  return (
    <button
      className={`${styles.formCancelButton} ${isDisabled ? styles.isDisabled : ""} ${rootClassName}`}
      style={rootStyle}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
    >
      {t("common.cancel")}
    </button>
  );
}

export default FormCancelButton;
