const cacheKeyBuilder = {
    // Domain
    competitions: ({}) => ["competitions", {}],
    competition: (id?: string) => ["competition", id],
};

const cacheKeyInvalidator = {
    // Competition
    competitions: () => ["competitions"],
    competition: (id?: string) => ["competition", id], 
};

export { cacheKeyBuilder, cacheKeyInvalidator };
