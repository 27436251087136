import msalManager from "@/auth/msalManager";
import { appEnv, multisportsApiGatewayKey } from "@/config";
import { APICompetition } from "./apiTypes";
import { Competition } from "../types/Competition";

/**
 * get the default headers for the API requests
 * @returns the default headers for the API requests
 */
export async function getDefaultHeaders() {
  const accessToken = await msalManager.getAccessToken();

  const defaultHeaders = {
    "Ocp-Apim-Subscription-Key": multisportsApiGatewayKey,
    Authorization: `Bearer ${accessToken}`,
    "x-application-name": "multisports-front-users",
    "x-env": appEnv,
  };

  return defaultHeaders;
}

/**
 * cast an APICompetition to a Competition
 * @param apiCompetition
 * @returns the Competition
 */
export function castApiCompetitionToCompetition(apiCompetition: APICompetition): Competition {
  return {
    id: apiCompetition.id,
    sportKey: apiCompetition.sport_key,
    name: apiCompetition.name,
    shortName: apiCompetition.short_name,
    logoUrl: apiCompetition.logo_url,
    gender: apiCompetition.gender,
    createdAt: apiCompetition.created_at,
    updatedAt: apiCompetition.updated_at,
    createdBy: apiCompetition.created_by,
    updatedBy: apiCompetition.updated_by,
  };
}
