import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";

import { UpdateCompetitionInputs } from "@rugby/api/CompetitionsApi";
import Loader from "@/ui-lib/loader/Loader";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { SportKey } from "@rugby/types/Sport";
import { Gender } from "@rugby/types/Gender";
import FormCancelButton from "@/ui-lib/form-cancel-button/FormCancelButton";

import usePageAdminCompetitionEdit from "./usePageAdminCompetitionEdit";

import styles from "./PageAdminCompetitionEdit.module.scss";

const PageAdminCompetitionEdit = () => {
  const { t } = useTranslation();

  const { competition, isLoading, updateCompetition, isUpdateCompetitionLoading } =
    usePageAdminCompetitionEdit();

  // Set the form values when the competition is fetched
  useEffect(() => {
    reset({
      sportKey: competition?.sportKey,
      name: competition?.name,
      shortName: competition?.shortName,
      logoUrl: competition?.logoUrl,
      gender: competition?.gender,
    });
  }, [competition]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateCompetitionInputs>({
    defaultValues: {
      sportKey: competition?.sportKey,
      name: competition?.name,
      shortName: competition?.shortName,
      logoUrl: competition?.logoUrl,
    },
  });

  // Block the user from leaving the page if there is unsaved data
  useBlocker(() => isDirty && !confirm(t("common.unsaved-data-confirm-leave-page")));

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.pageAdminCompetitionEdit}>
      <AdminHeader text={`${t("admin.rugby.competition.competition")} ${competition?.name}`} />
      <form onSubmit={handleSubmit(updateCompetition)}>
        <div className={styles.formInputs}>
          <FormSelect
            id="sportKey"
            className={styles.formSelect}
            labelName={t("admin.rugby.sport.sport")}
            options={[
              { value: SportKey.RUGBY_XV, label: t("admin.rugby.sport.rugby-xv") },
              { value: SportKey.RUGBY_VII, label: t("admin.rugby.sport.rugby-vii") },
            ]}
            textToDisplayIfError={`${t("admin.rugby.sport.sport")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.sportKey?.message}
            register={register}
          />
          <FormInput
            id="name"
            className={styles.formInput}
            labelName={t("admin.rugby.competition.name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
          />
          <FormInput
            id="shortName"
            className={styles.formInput}
            labelName={t("admin.rugby.competition.short-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.short-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.shortName?.message}
            register={register}
          />
          <FormInput
            id="logoUrl"
            className={styles.formInput}
            labelName={t("admin.rugby.competition.logo-url")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.logo-url")}")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.logoUrl?.message}
            register={register}
          />
          <FormSelect
            id="gender"
            className={styles.formSelect}
            labelName={t("admin.rugby.gender.gender")}
            textToDisplayIfError={`${t("admin.rugby.gender.gender")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.sportKey?.message}
            register={register}
            options={[
              { value: Gender.MALE, label: t("admin.rugby.gender.male") },
              { value: Gender.FEMALE, label: t("admin.rugby.gender.female") },
            ]}
          />
        </div>
        <div className={styles.formButtons}>
          <FormCancelButton
            onClick={() => reset()}
            isDisabled={!isDirty || isUpdateCompetitionLoading}
          />
          <FormSubmit
            value={t("admin.common.save")}
            disabled={!isDirty || isUpdateCompetitionLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default PageAdminCompetitionEdit;
