import { UseFormRegister } from "react-hook-form";

import { SelectOption } from "../select/Select";

import "./formSelect.scss";

type FormSelectProps = {
  id: string;
  labelName?: string;
  options: SelectOption[];
  textToDisplayIfError?: string;
  errorMessage?: string;
  register: UseFormRegister<any>; // Provide the missing type argument
  registerValueAsNumber?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue?: string;
  disabled?: boolean;
  className?: string;
};

function FormSelect({
  id,
  labelName,
  options,
  textToDisplayIfError,
  errorMessage,
  register,
  registerValueAsNumber,
  onChange,
  selectedValue,
  disabled = false,
  className,
}: FormSelectProps) {
  return (
    <div className={`form-select ${className}`}>
      {labelName && (
        <label htmlFor={id}>
          {labelName}{" "}
          <span className="required-star">
            {" "}
            {textToDisplayIfError && "*"}{" "}
            {errorMessage && <span className="error-message">{errorMessage}</span>}
          </span>
        </label>
      )}
      <select
        id={id}
        {...register(id, {
          required: textToDisplayIfError,
          valueAsNumber: registerValueAsNumber,
        })}
        onChange={(e) => {
          register(id).onChange(e);
          !!onChange && onChange(e);
        }}
        value={selectedValue}
        disabled={disabled}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FormSelect;
