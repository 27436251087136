import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateCompetitionInputs } from "@rugby/api/CompetitionsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import { SportKey } from "@/domains/rugby/types/Sport";
import { Gender } from "@/domains/rugby/types/Gender";
import FormSelect from "@/ui-lib/form-select/FormSelect";

import usePageAdminCompetitionCreate from "./usePageAdminCompetitionCreate";

import styles from "./PageAdminCompetitionCreate.module.scss";
import { toast } from "react-toastify";

const PageAdminCompetitionCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { createCompetition, isCreationSuccess } = usePageAdminCompetitionCreate();

  // Navigate to the competition page when the creation is successful
  useEffect(
    function navigateAfterCreation() {
      if (isCreationSuccess) {
        toast.success(t("admin.rugby.competition.create-competition-success"));
        navigate(`/admin/rugby/competitions`);
      }
    },
    [isCreationSuccess],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCompetitionInputs>({
    defaultValues: {
      sportKey: SportKey.RUGBY_XV,
      name: "",
      shortName: "",
      logoUrl: "",
      gender: Gender.MALE,
    },
  });

  return (
    <div className={styles.pageAdminCompetitionCreate}>
      <AdminHeader text={t("admin.rugby.competition.create-competition")} />
      <form onSubmit={handleSubmit(createCompetition)}>
        <div className={styles.formInputs}>
          <FormSelect
            id="sportKey"
            labelName={t("admin.rugby.sport.sport")}
            textToDisplayIfError={`${t("admin.rugby.sport.sport")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.sportKey?.message}
            register={register}
            options={[
              { value: SportKey.RUGBY_XV, label: t("admin.rugby.sport.rugby-xv") },
              { value: SportKey.RUGBY_VII, label: t("admin.rugby.sport.rugby-vii") },
            ]}
            className={styles.formSelect}
          />
          <FormInput
            id="name"
            labelName={t("admin.rugby.competition.name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
            className={styles.formInput}
          />
          <FormInput
            id="shortName"
            labelName={t("admin.rugby.competition.short-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.short-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
            className={styles.formInput}
          />
          <FormInput
            id="logoUrl"
            labelName={t("admin.rugby.competition.logo-url")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.rugby.competition.logo-url")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
            className={styles.formInput}
          />
          <FormSelect
            id="gender"
            labelName={t("admin.rugby.gender.gender")}
            textToDisplayIfError={`${t("admin.rugby.gender.gender")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.sportKey?.message}
            register={register}
            options={[
              { value: Gender.MALE, label: t("admin.rugby.gender.male") },
              { value: Gender.FEMALE, label: t("admin.rugby.gender.female") },
            ]}
            className={styles.formSelect}
          />
        </div>
        <div className={styles.formButtons}>
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/rugby/competitions`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminCompetitionCreate;
