import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@/ui-lib/loader/Loader";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormButton from "@/ui-lib/form-button/FormButton";

import usePageAdminCompetitionList, { ExtendedCompetition } from "./usePageAdminCompetitionList";

import styles from "./PageAdminCompetitionList.module.scss";

const PageAdminCompetitionList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { extendedCompetitions, isLoading, deleteCompetition, isDeleteCompetitionLoading } =
    usePageAdminCompetitionList();

  const columlHelper = createColumnHelper<ExtendedCompetition>();
  const columns = [
    columlHelper.accessor("sport.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.rugby.sport.sport"),
      maxSize: 100,
    }),
    columlHelper.accessor("logoUrl", {
      cell: (info) => (
        <img className={styles.logo} src={info.getValue()} alt={info.row.original.name} />
      ),
      header: () => t("admin.rugby.sport.logo"),
      maxSize: 50,
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.rugby.competition.name"),
    }),
    columlHelper.accessor("shortName", {
      cell: (info) => info.getValue(),
      header: () => t("admin.rugby.competition.short-name"),
      maxSize: 50,
    }),
    columlHelper.accessor("gender", {
      cell: (info) => t(`admin.rugby.gender.${info.getValue().toLowerCase()}`),
      header: () => t("admin.rugby.gender.gender"),
      maxSize: 100,
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.common.edit")}
            onClick={() => navigate(`/admin/rugby/competitions/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.common.delete")}
            onClick={() => {
              if (confirm(t("admin.rugby.competition.confirm-competition-deletion")))
                deleteCompetition(info.row.original.id);
            }}
            disabled={isDeleteCompetitionLoading}
          />
        </div>
      ),
      header: () => t("actions"),
      maxSize: 100,
    }),
  ];

  return (
    <div className={styles.pageAdminCompetitionList}>
      <AdminHeader text={t("admin.rugby.competition.competitions")} />
      <div className={styles.topRow}>
        <FormButton
          labelName={t("admin.common.create")}
          onClick={() => navigate(`/admin/rugby/competitions/create`)}
          className={styles.createButton}
        />
      </div>
      {isLoading ? (
        <Loader rootClassName={styles.loader} />
      ) : (
        <div className={styles.content}>
          <Table columns={columns} data={extendedCompetitions} hasGlobalFilter={true} />
        </div>
      )}
    </div>
  );
};

export default PageAdminCompetitionList;
