import styles from "./FormSubmit.module.scss";

type FormSubmitProps = {
  value: string;
  disabled?: boolean;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
};

function FormSubmit({ value, disabled = false, rootClassName, rootStyle }: FormSubmitProps) {
  return (
    <div className={`${styles.formSubmit} ${rootClassName}`} style={rootStyle}>
      <input
        type="submit"
        className={`${styles.submitInput} ${disabled ? styles.isDisabled : ""}`}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

export default FormSubmit;
