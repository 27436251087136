import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Table from "@ui-lib/table/Table";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";
import Loader from "@/ui-lib/loader/Loader";
import usePageAdminOrganizationCompetitions, {
  ExtendedCompetition,
} from "./usePageAdminOrganizationCompetitions";
import ButtonFavorite from "@/ui-lib/button-favorite/ButtonFavorite";
import { OrganizationSettingEntityType } from "@user/types/OrganizationSetting";

import "./pageAdminOrganizationCompetitions.scss";

const PageAdminOrganizationCompetitions = () => {
  const { t } = useTranslation();
  const { id: organizationId } = useParams();

  const {
    extendedCompetitions,
    sportRugby,
    isLoading,
    isError,
    createOrganizationCompetition,
    updateOrganizationCompetition,
    createOrganizationSetting,
    deleteOrganizationSetting,
  } = usePageAdminOrganizationCompetitions();

  // Organization api routes table initialization
  const columlHelper = createColumnHelper<ExtendedCompetition>();
  const columns = [
    columlHelper.accessor("sportName", {
      cell: () => (
        <>
          <p>{sportRugby?.name}</p>
        </>
      ),
      header: () => t("admin.user-management.sport.sports"),
      size: 50,
    }),
    columlHelper.accessor("name", {
      cell: (info) => (
        <div>
          <p>{info.row.original.name}</p>
        </div>
      ),
      header: () =>
        t("admin.user-management.organization-competitions.organization-competition-name"),
      size: 100,
    }),
    columlHelper.accessor("isFavorite", {
      cell: (info) => (
        <div>
          <ButtonFavorite
            isActive={info.row.original.isFavorite}
            onClick={() => {
              if (!organizationId || !sportRugby) return;

              if (
                info.row.original.isFavorite === true &&
                info.row.original.organizationSettingId !== undefined
              ) {
                deleteOrganizationSetting(organizationId, info.row.original.organizationSettingId);
              } else {
                createOrganizationSetting(organizationId, {
                  entityType: OrganizationSettingEntityType.FAVORITE_COMPETITION,
                  entityId: info.row.original.id.toString(),
                  settingValue: "true",
                  sportId: sportRugby.id,
                });
              }
            }}
          />
        </div>
      ),
      header: () => t("common.favorites"),
      size: 50,
    }),
    columlHelper.accessor("hasDynamicReportAccess", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasDynamicReportAccess}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                    hasDynamicReportAccess: !info.row.original.hasDynamicReportAccess,
                  })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasDynamicReportAccess: true,
                  });
            }}
          />
        </div>
      ),
      header: () =>
        t("admin.user-management.organization-competitions.access-to-competition-stats"),
      size: 100,
    }),
    columlHelper.accessor("hasFreeAccessToGames", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasFreeAccessToGames}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? info.row.original.hasFreeAccessToGames === true
                  ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                      hasFreeAccessToGames: false,
                    })
                  : updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                      hasFreeAccessToGames: true,
                      hasGamesPurchaseBlocked: false,
                    })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasFreeAccessToGames: true,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.user-management.organization-competitions.free-access-to-games"),
      size: 100,
    }),
    columlHelper.accessor("hasGamesPurchaseBlocked", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasGamesPurchaseBlocked}
            isDisabled={info.row.original.hasFreeAccessToGames}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                    hasGamesPurchaseBlocked: !info.row.original.hasGamesPurchaseBlocked,
                  })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasGamesPurchaseBlocked: true,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.user-management.organization-competitions.block-games-purchase"),
      size: 100,
    }),
    columlHelper.accessor("hasCompetitionDownloadsBlocked", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasCompetitionDownloadsBlocked}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                    hasCompetitionDownloadsBlocked:
                      !info.row.original.hasCompetitionDownloadsBlocked,
                  })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasCompetitionDownloadsBlocked: true,
                  });
            }}
          />
        </div>
      ),
      header: () =>
        t("admin.user-management.organization-competitions.block-competition-related-downloads"),
      size: 100,
    }),
    columlHelper.accessor("hasGamesDownloadsBlocked", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasGamesDownloadsBlocked}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                    hasGamesDownloadsBlocked: !info.row.original.hasGamesDownloadsBlocked,
                  })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasGamesDownloadsBlocked: true,
                  });
            }}
          />
        </div>
      ),
      header: () =>
        t("admin.user-management.organization-competitions.block-games-related-downloads"),
      size: 100,
    }),
    columlHelper.accessor("hasFreeAccessToTeams", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.hasFreeAccessToTeams}
            onClick={() => {
              info.row.original.organizationCompetitionId
                ? updateOrganizationCompetition(info.row.original.organizationCompetitionId, {
                    hasFreeAccessToTeams: !info.row.original.hasFreeAccessToTeams,
                  })
                : createOrganizationCompetition({
                    organizationId: organizationId as string,
                    competitionId: info.row.original.id,
                    hasFreeAccessToTeams: true,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.user-management.organization-competitions.free-access-to-teams"),
      size: 100,
    }),
  ];

  if (isLoading) {
    return <Loader rootClassName="page-admin-organization-competitions-loader" />;
  }

  if (isError) {
    return <div>{t("admin.error")}</div>;
  }

  return (
    <div className="page-admin-organization-competitions">
      <div className="title-bar">
        <div className="title">
          {t("admin.user-management.organization.organization-competitions")}
        </div>
      </div>
      <Table
        columns={columns}
        data={extendedCompetitions ?? []}
        hasGlobalFilter={true}
        maxHeight="70vh"
      />
    </div>
  );
};

export default PageAdminOrganizationCompetitions;
