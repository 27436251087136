/**
 * API Types
 *
 * These types are used to define the shape of the data that is sent and received from the API.
 * They are not used in the application logic. The ones used in the application logic are in the `types` folder.
 * The function to cast from API types to application types can be found in the api `utils` file.
 */

export enum APISportKey {
  RUGBY_XV = "RUGBY_XV",
  RUGBY_VII = "RUGBY_VII",
}

export enum APIGender {
  MALE = "Male",
  FEMALE = "Female",
}

export interface APICompetition {
  id: string; // UUID v4
  sport_key: APISportKey;
  name: string;
  short_name: string;
  logo_url: string;
  gender: APIGender;
  created_at: string; // Datetime
  created_by: string; // UUID v4
  updated_at: string; // Datetime
  updated_by: string; // UUID v4
}
