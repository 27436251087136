import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import Loader from "@/ui-lib/loader/Loader";
import AiaSportsLogo from "@/assets/logos/logo_aia_sports_couleur_negatif.png";

function EnsureAuth({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { t } = useTranslation();

  const { isConnected, isConnectionLoading, isLoading } = useContext(userContext);

  // Redirect to login if user is not connected
  if (!isConnectionLoading && !isConnected) {
    console.info("User is not connected, redirecting to login");
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Show loading screen when connection is in progress
  // This rule ensures that the user and his related attributes (current sport, ...) are loaded before rendering the app
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={AiaSportsLogo}
          alt="Logo AIA Sports"
          width={500}
          style={{ marginBottom: "50px" }}
        />
        <div style={{ fontSize: "2rem", color: "white" }}>
          {t("common.connection-in-progress")}.
        </div>
        <div style={{ fontSize: "2rem", color: "white" }}>{t("common.please-wait")}...</div>
        <Loader
          size="large"
          rootStyle={{
            marginTop: "2rem",
          }}
        />
      </div>
    );
  }

  return children;
}

export default EnsureAuth;
