import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import CompetitionsApi, {
  CreateCompetitionInputs,
  UpdateCompetitionInputs,
} from "@rugby/api/CompetitionsApi";
import { Competition } from "@rugby/types/Competition";
import { cacheKeyBuilder, cacheKeyInvalidator } from "@rugby/api/apiCache";
import { APIError, APIErrorType } from "@rugby/types/ApiError";

interface useCompetitionOptions {
  competitionId?: string;
  fetchDetails?: boolean; // Enable fetching the competition details
}

function useCompetition(options: Partial<useCompetitionOptions> = {}): {
  competition: Competition | undefined;
  isLoading: boolean;
  isError: boolean;
  createCompetition: (createCompetitionInputs: CreateCompetitionInputs) => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdCompetition: Competition | undefined;
  updateCompetition: (inputs: UpdateCompetitionInputs) => void;
  isUpdateCompetitionLoading: boolean;
  deleteCompetition: (competitionId: string) => void;
  isDeleteCompetitionLoading: boolean;
} {
  const { competitionId, fetchDetails = false } = options;

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Fetch the competition
  const {
    data: competition,
    isLoading: isCompetitionLoading,
    isError: isCompetitionError,
  } = useQuery({
    queryKey: cacheKeyBuilder.competition(competitionId),
    queryFn: () => {
      if (!competitionId) return;
      return CompetitionsApi.show(competitionId);
    },
    enabled: !!competitionId && fetchDetails,
  });

  // Create a competition
  const {
    mutate: createCompetitionInApi,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
    data: createdCompetition,
  } = useMutation({
    mutationFn: (newCompetition: CreateCompetitionInputs) => {
      return CompetitionsApi.create(newCompetition);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.competitions(),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function createCompetition(createCompetitionInputs: CreateCompetitionInputs) {
    createCompetitionInApi(createCompetitionInputs);
  }

  // Update the competition details
  const { mutate: updateCompetitionInApi, isPending: isUpdateCompetitionLoading } = useMutation({
    mutationFn: (params: { competitionId: string; inputs: UpdateCompetitionInputs }) =>
      CompetitionsApi.update(params.competitionId, params.inputs),
    onSuccess: (data: Competition) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.competition(data.id),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.competitions(),
      });
    },
    onError: (error: AxiosError) => {
      console.log(error);
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.rugby.competition.name-already-exists"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function updateCompetition(inputs: UpdateCompetitionInputs) {
    if (!competitionId) return;

    updateCompetitionInApi({ competitionId, inputs });
  }

  // Delete the competition
  const { mutate: deleteCompetitionInAPI, isPending: isDeleteCompetitionLoading } = useMutation({
    mutationFn: (inputs: { competitionId: string }) => {
      return CompetitionsApi.delete(inputs.competitionId);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.competitions(),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.competition(variables.competitionId),
      });
    },
    onError: (error: AxiosError) => {
      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteCompetition(competitionId: string) {
    deleteCompetitionInAPI({ competitionId });
  }

  const isLoading = isCompetitionLoading;

  const isError = isCompetitionError;

  return {
    competition,
    isLoading,
    isError,
    createCompetition,
    isCreationPending,
    isCreationSuccess,
    createdCompetition,
    updateCompetition,
    isUpdateCompetitionLoading,
    deleteCompetition,
    isDeleteCompetitionLoading,
  };
}

export default useCompetition;
