import "./formButton.scss";

type FormButtonProps = {
  labelName: string;
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
  className?: string;
};

function FormButton({
  labelName,
  onClick,
  color,
  disabled = false,
  className = "",
}: FormButtonProps) {
  return (
    <button
      className={`form-button ${className}`}
      onClick={onClick}
      disabled={disabled}
      type="button"
      style={{ color }}
    >
      {labelName}
    </button>
  );
}

export default FormButton;
