import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition,
  getDefaultHeaders,
} from "./utils";
import { OrganizationRugbyCompetition } from "@user/types/OrganizationRugbyCompetition";
import { APIOrganizationRugbyCompetition } from "./apiTypes";

export interface CreateOrganizationCompetitionsInputs {
  organizationId: string;
  competitionId: number;
  hasDynamicReportAccess?: boolean;
  hasFreeAccessToGames?: boolean;
  hasGamesPurchaseBlocked?: boolean;
  hasCompetitionDownloadsBlocked?: boolean;
  hasGamesDownloadsBlocked?: boolean;
  hasFreeAccessToTeams?: boolean;
}
export interface UpdateOrganizationCompetitionsInputs {
  hasDynamicReportAccess?: boolean;
  hasFreeAccessToGames?: boolean;
  hasGamesPurchaseBlocked?: boolean;
  hasCompetitionDownloadsBlocked?: boolean;
  hasGamesDownloadsBlocked?: boolean;
  hasFreeAccessToTeams?: boolean;
}

export default class OrganizationCompetitionsApi {
  static domain: string = "user";
  static resource: string = "organization-competitions-rugby";

  static async create(
    createOrganizationCompetition: Partial<CreateOrganizationCompetitionsInputs>,
  ): Promise<OrganizationRugbyCompetition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const data = {
      competition_id: createOrganizationCompetition.competitionId,
      organization_id: createOrganizationCompetition.organizationId,
      has_dynamic_report_access: createOrganizationCompetition.hasDynamicReportAccess ?? false,
      has_free_access_to_games: createOrganizationCompetition.hasFreeAccessToGames ?? false,
      has_games_purchase_blocked: createOrganizationCompetition.hasGamesPurchaseBlocked ?? false,
      has_competition_downloads_blocked:
        createOrganizationCompetition.hasCompetitionDownloadsBlocked ?? false,
      has_games_downloads_blocked: createOrganizationCompetition.hasGamesDownloadsBlocked ?? false,
      has_free_access_to_teams: createOrganizationCompetition.hasFreeAccessToTeams ?? false,
    };
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: data,
    });

    const apiOrganizationCompetition: APIOrganizationRugbyCompetition = response.data;

    const organizationCompetition: OrganizationRugbyCompetition =
      castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition(apiOrganizationCompetition);

    return organizationCompetition;
  }

  static async update(
    id: string,
    updateOrganizationCompetition: Partial<UpdateOrganizationCompetitionsInputs>,
  ): Promise<OrganizationRugbyCompetition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const body = {
      has_dynamic_report_access: updateOrganizationCompetition.hasDynamicReportAccess,
      has_free_access_to_games: updateOrganizationCompetition.hasFreeAccessToGames,
      has_games_purchase_blocked: updateOrganizationCompetition.hasGamesPurchaseBlocked,
      has_competition_downloads_blocked:
        updateOrganizationCompetition.hasCompetitionDownloadsBlocked,
      has_games_downloads_blocked: updateOrganizationCompetition.hasGamesDownloadsBlocked,
      has_free_access_to_teams: updateOrganizationCompetition.hasFreeAccessToTeams,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiOrganizationCompetition: APIOrganizationRugbyCompetition = response.data;

    const organizationCompetition: OrganizationRugbyCompetition =
      castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition(apiOrganizationCompetition);

    return organizationCompetition;
  }
}
