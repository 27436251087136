import { useParams } from "react-router-dom";

import { UpdateCompetitionInputs } from "@rugby/api/CompetitionsApi";
import useCompetition from "@rugby/hooks/useCompetition";
import { Competition } from "@rugby/types/Competition";

function usePageAdminCompetitionEdit(): {
  competition?: Competition;
  isLoading: boolean;
  updateCompetition: (updatedCompetition: UpdateCompetitionInputs) => void;
  isUpdateCompetitionLoading: boolean;
} {
  const { id: competitionId } = useParams();

  // Fetch the competition
  const {
    competition,
    isLoading: isCompetitionLoading,
    updateCompetition,
    isUpdateCompetitionLoading,
  } = useCompetition({
    competitionId,
    fetchDetails: true,
  });

  // Build global isLoading flag
  const isLoading = isCompetitionLoading;

  return {
    competition,
    isLoading,
    updateCompetition,
    isUpdateCompetitionLoading,
  };
}

export default usePageAdminCompetitionEdit;
