import { useQuery } from "@tanstack/react-query";

import { Competition } from "@rugby/types/Competition";
import CompetitionsApi from "@rugby/api/CompetitionsApi";
import { cacheKeyBuilder } from "@rugby/api/apiCache";

interface useCompetitionsOptions {
  skip?: boolean;
}

function useCompetitions(options: Partial<useCompetitionsOptions> = {}): {
  competitions: Competition[];
  isLoading: boolean;
  isError: boolean;
} {
  const { skip = false } = options;

  // Fetch the list of competitions
  const {
    data: competitions = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: cacheKeyBuilder.competitions({}),
    queryFn: () => CompetitionsApi.list(),
    enabled: !skip,
  });

  return {
    competitions,
    isLoading,
    isError,
  };
}

export default useCompetitions;
