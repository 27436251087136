import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { Competition } from "@rugby-former/types/Competitions";
import { getDefaultHeaders } from "./utils";

export default class CompetitionsApi {
  static domain = "rugby-former";
  static resource = "competitions";

  static async getAll(sport_id?: number): Promise<Competition[]> {
    const defaultHeaders = await getDefaultHeaders();

    let url = `${multisportsApiUrl}/${this.domain}/${this.resource}/all`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const competitions: Competition[] = response.data;
    const sortedCompetitions = competitions.sort((a, b) => (a.name >= b.name ? 1 : -1)); // Should be removed once data comes from multisports db (already sorted)

    return sortedCompetitions;
  }
}
